"use client";
import React, { useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";

import { Container } from "../../container";
import BaseButton from "../../button/base-button";
import { useRouter } from "next/navigation";
import { AnimatedText } from "@/app/components/animated-text";

const FirstSection = () => {
  const router = useRouter();
  const [isBouncing, setIsBouncing] = useState(false);

  useEffect(() => {
    setIsBouncing(true);
    const timer = setTimeout(() => {
      setIsBouncing(false);
    }, 10000); // 10 seconds

    return () => clearTimeout(timer);
  }, []);
  const Icon = (
    <CheckIcon className="block h-5 w-5 text-green-500" aria-hidden="true" />
  );

  const items = [
    {
      icon: Icon,
      text: "Flexible & convenient savings plans",
    },
    {
      icon: Icon,
      text: "Endowment plan that guarantees future education",
    },
    {
      icon: Icon,
      text: "Peace of mind from uncertainties.",
    },
  ];

  return (
    <div>
      <div className="bg-[#bdcafd]">
        <Container>
          <div className="flex flex-col items-center sm:flex-row md:flex-row lg:flex-row w-[100%] text-[#000000] bg-[#bdcafd]">
            <div className="sm:w-[100%] md:w-[100%] lg:w-[60%]">
              <AnimatedText
                textLarge={true}
                delay={50}
                description="Plan and secure your"
              />
              <div className="mb-5">
                <AnimatedText
                  textLarge={true}
                  delay={2000}
                  description="child’s education with EdGo"
                />
              </div>
              <ul>
                {items?.map((fx, idx) => (
                  <li key={idx} className="flex gap-3 items-center">
                    {fx.icon} <p>{fx.text}</p>
                  </li>
                ))}
              </ul>
              <div className="w-full my-10 flex justify-center sm:flex sm:justify-start">
                <BaseButton onClick={() => router.push("/sign-up")}>
                  Get started for free
                </BaseButton>
              </div>
            </div>
            <div className="sm:w-[100%] md:w-[100%] lg:w-[40%]">
              <img
                className={`${isBouncing ? "bounce" : ""}`}
                src="/images/section1.png"
                alt=""
              />
            </div>
          </div>
        </Container>
      </div>
      <div className="border border-b-1 border-[#D9D9D9] mb-5"></div>
    </div>
  );
};

export { FirstSection };
