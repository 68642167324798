"use client";
import React from "react";
import { Container } from "../../container";
// import { CallBackModal } from "../../call-back-modal";
import { AnimatedTextGeneral } from "@/app/components/animated-text";
import { useRouter } from "next/navigation";

const TabOne = () => {
  const router = useRouter();
  // const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Container>
      <div
        id="whats-edgo"
        className="flex justify-between flex-col sm:flex-row md:flex-row lg:flex-row w-[100%] text-[#000000] my-20"
      >
        <div className="sm:w-[100%] md:w-[100%] lg:w-[40%]">
          <img src="/images/tabone.png" alt="" />
        </div>
        <div className="sm:w-[100%] md:w-[100%] lg:w-[55%] mt-8 sm:mt-5">
          <div className="mb-5 text-center sm:text-left">
            <AnimatedTextGeneral
              textLarge={true}
              description="Endowment plan that guarantees"
              delay={50}
            />
            <AnimatedTextGeneral
              textLarge={true}
              description="your child's education even in"
              delay={500}
            />
            <AnimatedTextGeneral
              textLarge={true}
              description="your absence"
              delay={1000}
            />
          </div>

          <p className="text-[12px] lg:text-[14px] text-center sm:text-left">
            With EdGo, you can plan, save for and secure your child's education
            with ease. Our AI powered budgeting tool helps you make informed
            savings decision - allowing you to conveniently save monthly,
            quarterly, yearly or one off, with a target savings goal over a
            period of 5 - 25 years. Our flexible approach empowers you to tailor
            your savings plan according to your financial capabilities and
            aspirations for your children's education. The embedded insurance
            guarantees the continuity of your child's education if for any
            reason you're no longer able to fulfil your savings obligation due
            to unforeseen events such as death or accident. When none of these
            happens, we're very glad to pay you your total savings plus interest
            at maturity of your plan. You can also decide to cancel the plan at
            anytime
          </p>
          <p className="mt-5 text-[16px]">
            Need more explanation?{" "}
            <span
              onClick={() => router.push("/request-call-back")}
              className="text-[#264FFA] cursor-pointer"
            >
              Request a call back
            </span>
            .
          </p>
        </div>
      </div>
      {/* <CallBackModal isOpen={isOpen} setIsOpen={setIsOpen} /> */}
    </Container>
  );
};

export default TabOne;
