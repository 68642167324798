import React from "react";
import { BaseInput, TextArea } from "@/app/components/inputs";
import { CenterModal } from "./modals";
import BaseButton from "./button/base-button";

interface ContactUsModalProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}

const ContactUsModal = ({ isOpen, setIsOpen }: ContactUsModalProps) => {
  return (
    <CenterModal open={isOpen} setOpen={setIsOpen} title="Contact Us">
      <p className="my-5 text-center text-[16px]">
        Please call us on 0916 410 8484 if you have any queries or <br />{" "}
        complete the form below.
      </p>
      <BaseInput label="First name" id="first_name" placeholder="Alfred" />
      <div className="my-2">
        <BaseInput label="Last name" id="last_name" placeholder="Bryan" />
      </div>
      <div className="my-2">
        <BaseInput
          label="Email address"
          id="email"
          placeholder="xyz@gmail.com"
        />
      </div>
      <div className="my-2">
        <BaseInput label="Phone number" id="number" placeholder="102 445 677" />
      </div>
      <div className="mb-10">
        <TextArea
          placeholder="Enter message here..."
          label="Message"
          id="message"
        />
      </div>

      <div>
        <BaseButton fullWidth={true}>Send</BaseButton>
      </div>
    </CenterModal>
  );
};

export { ContactUsModal };
