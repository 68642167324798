"use client";
import { useRouter } from "next/navigation";
import React from "react";
import { IoArrowBack, IoMenu, IoNotificationsOutline } from "react-icons/io5";
import { SideBar } from "../side-bar";

interface DashboardWrapperProps {
  children: React.ReactNode;
  title?: string;
}

const DashboardWrapper = ({ children, title }: DashboardWrapperProps) => {
  const router = useRouter();
  return (
    <div className="flex flex-col items-center p-5 min-h-screen ">
      <div className="w-full max-w-sm bg-[#f5fffb] px-5 has-[*:checked]:overflow-hidden relative">
        <div className="flex justify-between items-center">
          <IoArrowBack
            className="cursor-pointer"
            onClick={() => router.back()}
            size={25}
          />
          <div className="flex flex-shrink-0 items-center">
            <img
              className="h-[100px] w-auto"
              src="/svgs/logo_blue.svg"
              alt="Your Company"
            />
          </div>
          <div className="flex items-center gap-6">
            <IoNotificationsOutline size={25} />
            <label htmlFor="sidebar-toggle">
              <IoMenu size={25} />
            </label>
          </div>
        </div>
        {title && (
          <h1 className="text-2xl font-bold text-[#091B66] capitalize">
            {title}
          </h1>
        )}
        {children}
        <SideBar />
      </div>
    </div>
  );
};

export { DashboardWrapper };
